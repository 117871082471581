import { Icon } from '@chakra-ui/react'

const IconSvg = (props, color) => (
  <Icon width="49px" height="52px" viewBox="0 0 49 52" fill="none" {...props}>
    <path
      d="M19.3292 10.5909H14.9949L22.3146 20.8294L14.696 31.4215H18.9107L26.5256 20.8629L19.3292 10.5909ZM33.9686 10.5909H29.7539L25.1543 17.1635L27.1832 20.0627L33.9686 10.5909ZM27.243 21.5663L25.1169 24.5177L29.937 31.4215H34.2675L27.243 21.5663Z"
      fill={color}
    />
    <path
      d="M40.7839 32.7725C42.9632 29.7794 44.2694 26.2444 44.5583 22.5583C44.8472 18.8721 44.1074 15.1781 42.4208 11.8844C40.7342 8.59074 38.1663 5.82555 35.0009 3.89434C31.8354 1.96313 28.1957 0.941071 24.4836 0.941071C20.7716 0.941071 17.1318 1.96313 13.9664 3.89434C10.801 5.82555 8.23312 8.59074 6.54648 11.8844C4.85985 15.1781 4.1201 18.8721 4.40897 22.5583C4.69784 26.2444 6.00408 29.7794 8.18337 32.7725L0.120117 44.1386H4.33483L12.6484 32.4003L12.7082 32.3184C10.4823 30.0181 8.98495 27.1164 8.40268 23.9746C7.8204 20.8328 8.17893 17.5898 9.43361 14.6495C10.6883 11.7092 12.7837 9.20152 15.4588 7.43878C18.1339 5.67605 21.2705 4.73615 24.478 4.73615C27.6855 4.73615 30.8222 5.67605 33.4973 7.43878C36.1724 9.20152 38.2678 11.7092 39.5225 14.6495C40.7771 17.5898 41.1357 20.8328 40.5534 23.9746C39.9711 27.1164 38.4738 30.0181 36.2478 32.3184L36.3114 32.404L44.625 44.1535H48.8397L40.7839 32.7725Z"
      fill="#5F8EB6"
    />
    <path
      d="M35.1195 40.8226L31.5325 35.7015C29.3311 36.7476 26.9228 37.2906 24.4836 37.2906C22.0445 37.2906 19.6362 36.7476 17.4348 35.7015L13.7731 40.9119L9.21838 47.4063L10.642 51.3551L18.4885 40.1601C22.383 41.3601 26.5506 41.3601 30.4452 40.1601L38.2917 51.3551L39.7153 47.4063L35.1195 40.8263"
      fill="#5F8EB6"
    />
    <path
      d="M16.3774 35.1283C15.3888 34.565 14.462 33.9002 13.6124 33.1446L10.7952 37.1343L5.87799 44.1386H8.03392L8.7289 46.059L16.3849 35.1507L16.3774 35.1283Z"
      fill="#5F8EB6"
    />
    <path
      d="M38.2506 37.2385L35.3735 33.1446H35.3511C34.5017 33.8977 33.5763 34.5612 32.5898 35.1246V35.1469L40.2384 46.059L40.9333 44.1424H43.0893L38.2319 37.246L38.2506 37.2385Z"
      fill="#5F8EB6"
    />
  </Icon>
)

export const BRONZEIcon = (props) => {
  return IconSvg(props, '#A27D5D')
}

export const SILVERIcon = (props) => {
  return IconSvg(props, '#A7A9AC')
}

export const GoldIcon = (props) => {
  return IconSvg(props, '#E3B756')
}

export const PLATINUMIcon = (props) => {
  return IconSvg(props, '#2E414D')
}
