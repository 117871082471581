export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? ''

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.svg'

export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

export const APP_CONTEXT = process.env.NEXT_PUBLIC_APP_CONTEXT ?? 'STS'
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION ?? 'R1'
export const HOME_BREADCRUMB = process.env.NEXT_PUBLIC_RELEASE_HOME || '/'

/* Brand name determines what contentful content will be pulled
for ex:
- single site can set BRAND_NAME to '',
- multi-site: set BRAND_A, BRAND_B etc
*/
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? ''

export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE

export const PRODUCT_CHANNEL =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_INVENTORY_CHANNEL ?? (BRAND_NAME ? `${BRAND_NAME}-channel` : undefined)

export const countries = [
  {
    name: 'Canada',
    code: 'CA',
    intlId: 'text.canada',
  },
  {
    name: 'United States',
    code: 'US',
    intlId: 'text.unitedStates',
  },
]

export const RICHTEXT_SCHEMA = {
  //SCHEMA need to be match with CMS to fetch content
  pdp: {
    shipingAndReturn: 'product/category/{{category}}/shipping_and_return',
  },
  account: {
    shipingAndReturn: 'account/shipping_and_return',
  },
}

export const ENABLE_BOLD_CHECKOUT = process.env.NEXT_PUBLIC_CHECKOUT
  ? process.env.NEXT_PUBLIC_CHECKOUT === 'bold'
  : false

export const BOLD_CHECKOUT_HOST = process.env.NEXT_PUBLIC_BOLD_CHECKOUT_HOST ?? ''

export const BOLD_COMMERCE_SHOP_ALIAS = process.env.NEXT_PUBLIC_BOLD_COMMERCE_SHOP_ALIAS ?? ''

export const SITEMAP_CONFIG = {
  LIMIT: 99,
  LOCALE: 'en-US',
  CURRENCY: 'USD',
  PARTITION_UUID: '77777777-7777-7777-7777-777777777777',
  SITEMAP_INDEX_SLUG: 'index.xml',
  SITEMAP_PRODUCT_PATH: 'sitemap/products-{page}.xml',
  SITEMAP_GENERIC_PAGE_PATH: 'sitemap/pages-{page}.xml',
  SITEMAP_CATEGORY_PATH: 'sitemap/categories-{page}.xml',
  PRODUCT_REGEX: /^products-(\d+)\.xml$/,
  GENERIC_PAGE_REGEX: /^pages-*-(\d+)\.xml$/,
  CATEGORY_REGEX: /^categories-*-(\d+)\.xml$/,
} as const

// DATADOG
export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? ''
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? ''
export const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV ?? ''
export const DATADOG_SITE = process.env.NEXT_PUBLIC_DATADOG_SITE ?? ''
export const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? ''
export const DATADOG_SAMPLE_RATE = parseInt(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE ?? '0')
export const DATADOG_REPLAY = parseInt(process.env.NEXT_PUBLIC_DATADOG_REPLAY ?? '0')

export const NOT_MATCH_PAGE_TITLE = '[DXL] - 404 Not Found'
export const NOT_MATCH_PAGE_CONTENT_TYPE = 'component_banner_split'

export const SITE_CONFIG_NAME = '[DXL STS] Site Config'
export const SITE_CONFIG_CONTENT_TYPE = 'site_config'

export const MAXIMUM_MEGA_MENU_GROUP_CHILDREN = 5

export const COOKIE_PRO_VERSION_ID = process.env.NEXT_PUBLIC_COOKIE_PRO_VERSION_ID ?? ''
export const COMPOSABLE_ENV = process.env.NEXT_PUBLIC_COMPOSABLE_ENV ?? ''
export const IS_PREVIEW_MODE = process.env.NEXT_PUBLIC_IS_PREVIEW_MODE ?? ''
export const LOCALE = 'en-US'
export const STRICTLY_NECESSARY_COOKIE_CATEGORY = 'optanon-category-C0001'
export const PERFORMANCE_COOKIE_CATEGORY = 'optanon-category-C0002'
export const FUNCTIONAL_COOKIE_CATEGORY = 'optanon-category-C0003'
export const TARGETING_COOKIE_CATEGORY = 'optanon-category-C0004'
export const SOCIAL_MEDIA_COOKIE_CATEGORY = 'optanon-category-C0005'

export const MERKLE_URL = process.env.NEXT_PUBLIC_MERKLE_URL ?? ''
export const ATG_BACKEND_ENDPOINT_DOMAIN = process.env.NEXT_PUBLIC_ATG_BE_DOMAIN ?? ''
export const FORTER_SITE_ID = process.env.NEXT_PUBLIC_FORTER_SITE_ID ?? ''
export const BORDERFREE_ORDER_TRACKING_URL = process.env.NEXT_PUBLIC_BORDERFREE_ORDER_TRACKING_URL ?? ''
export const GOOGLE_SIGNIN_ACCOUNT_ID = process.env.NEXT_PUBLIC_GOOGLE_SIGNIN_ACCOUNT_ID ?? ''
export const EPSILON_SCRIPT_URL = process.env.NEXT_PUBLIC_EPSILON_SCRIPT_URL ?? ''
export const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? ''
export const YOTTAA_URL = process.env.NEXT_PUBLIC_YOTTAA_SCRIPT_URL ?? ''
export const BORDER_FREE_WELCOME_SCRIPT_URL = process.env.NEXT_PUBLIC_BORDER_FREE_WELCOME_SCRIPT_URL

export const POWERREVIEWS_MERCHANT_GROUP_ID = process.env.NEXT_PUBLIC_POWERREVIEWS_MERCHANT_GROUP_ID ?? ''
export const POWERREVIEWS_MERCHANT_ID = process.env.NEXT_PUBLIC_POWERREVIEWS_MERCHANT_ID ?? ''
export const POWERREVIEWS_API_KEY = process.env.NEXT_PUBLIC_POWERREVIEWS_API_KEY ?? ''

export const FIND_MINE_APPLICATION_ID = process.env.NEXT_PUBLIC_FINDMINE_APPLICATION_ID ?? ''
export const BRANCH_IO_SCRIPT_KEY = process.env.NEXT_PUBLIC_BRANCH_IO_SCRIPT_KEY ?? ''
export const EXPERIAN_API_TOKEN_KEY = process.env.NEXT_PUBLIC_EXPERIAN_API_TOKEN_KEY ?? ''
export const TEALIUM_SITE_ID = 'dxl'

export const CERTONA_CARTPAGE_ENTRY_ID = process.env.NEXT_PUBLIC_CERTONA_CARTPAGE_ENTRY_ID ?? 'bltdb7d1b3af37fd6b4'
export const CERTONA_SEARCHPAGE_YMAL_UID = process.env.NEXT_PUBLIC_CERTONA_SEARCHPAGE_YMAL_UID ?? 'blt3ede84f973b7199e'
export const CERTONA_SEARCHPAGE_RV_UID = process.env.NEXT_PUBLIC_CERTONA_SEARCHPAGE_RV_UID ?? 'bltfe5be403f77aff0f'
export const CERTONA_CATEGORY_YMAL_UID = process.env.NEXT_PUBLIC_CERTONA_CATEGORY_YMAL_UID ?? 'bltbb54957dc4e4f349'
export const CERTONA_SUBCATEGORY_YMAL_UID =
  process.env.NEXT_PUBLIC_CERTONA_SUBCATEGORY_YMAL_UID ?? 'bltdaca78d1fc24e426'
export const CERTONA_SUBSUBCATEGORY_YMAL_UID =
  process.env.NEXT_PUBLIC_CERTONA_SUBSUBCATEGORY_YMAL_UID ?? 'blt20bbf451ea65bd79'
export const CERTONA_CATEGORY_RV_UID = process.env.NEXT_PUBLIC_CERTONA_CATEGORY_RV_UID ?? 'blt44229fa39eef590c'
export const CERTONA_BRANDS_YMAL_UID = process.env.NEXT_PUBLIC_CERTONA_BRANDS_YMAL_UID ?? 'blte1fb1e9d048ad45e'

export const CERTONA_PDP_YMAL_UID = process.env.NEXT_PUBLIC_CERTONA_PDP_YMAL_UID ?? 'blt0ec5de8fbb08dabd'
export const CERTONA_PDP_PAP_UID = process.env.NEXT_PUBLIC_CERTONA_PDP_PAP_UID ?? 'bltb14be1a027b2157e'
export const CERTONA_PDP_RV_UID = process.env.NEXT_PUBLIC_CERTONA_PDP_RV_UID ?? 'bltfbd53d1115ce8872'

export const CT_DXL_PRODUCT_TYPE_ID = process.env.NEXT_PUBLIC_CT_DXL_PRODUCT_TYPE_ID ?? ''

export const GIFT_CARD_IMAGE_URL =
  process.env.NEXT_PUBLIC_GIFT_CARD_IMAGE_URL ??
  'https://images.contentstack.io/v3/assets/blt58e3dfc8365d0ef2/blt6fad1ee7819b22ac/6637df9cc4443473aca7c51c/Media_Image.png'
export const DONATION_IMAGE_URL =
  process.env.NEXT_PUBLIC_DONATION_IMAGE_URL ??
  'https://images.contentstack.io/v3/assets/blt58e3dfc8365d0ef2/blta5a636ff1700175e/666b83f0371bcaea69b8e5ba/0a291d3b2aa6a6d573d26c7dbf45fa0a.jpeg?branch=development'
export const MAX_ADD_TO_CART_COUNT = 99
export const LOW_STOCK_COUNT = 5

// Afterpay messaging

export const AFTERPAY_MESSAGING_PLACEMENT_ID = process.env.NEXT_PUBLIC_AFTERPAY_MESSAGING_PLACEMENT_ID ?? ''
export const AFTERPAY_MESSAGING_MPID = process.env.NEXT_PUBLIC_AFTERPAY_MESSAGING_MPID ?? ''
export const AFTERPAY_MESSAGING_SCRIPT_SRC = process.env.NEXT_PUBLIC_AFTERPAY_MESSAGING_SCRIPT_SRC ?? ''

export const giftCardDescription = `The perfect fit every time. You can use our gift cards in our stores, phone purchase or at online checkout. Select one of our predetermined values or enter in your own amount. Gift cards are sent free of charge via standard shipping by the United States Postal Service and usually arrive within one week of purchase.`
export const giftCardTerms =
  'This card is eligible for any in-store, online or phone purchase. It can be reloaded and is reusable. Unless required by law, this card cannot be redeemed for cash. Not responsible for lost or stolen cards. For questions or balance inquires about gift cards, call 1-800-206-9307. Protect this card and treat it as you would cash. This card is issued by Capture, LLC.'
export const stJudeDescription = `DXL Men’s Apparel proudly supports St. Jude Children’s Research Hospital.® Your support and commitment enables St. Jude to continue to uphold its lifesaving mission: Finding cures. Saving children.® St. Jude is leading the way the world understands, treats, and defeats childhood cancer and other deadly diseases. At St. Jude, families never receive a bill for treatment, housing, travel, or meals – because all a family should have to focus on is helping their child live. <br><br>
Please donate to help the kids of St. Jude. No donation amount is too big or too small, as every dollar goes to support the lifesaving mission of St. Jude.`

export const GIFT_CARD_PRODUCT_ID = 'prod280002'
export const GIFT_CARD_SKU = '187078'
export const GIFT_CARD_PRODUCT_NAME = 'MULTI-COMPANY GIFT CARD'

export const DONATION_PRODUCT_ID = 'item2630'
export const DONATION_SKU = '586635'
export const DONATION_PRODUCT_NAME = 'ST JUDE DONATION'

export const IS_LOCAL = process.env.NODE_ENV === 'development'
export const NEW_ARRIVAL_SLUG = 'new'
export const NEW_ARRIVAL_THRESHOLD = 5184000000
export const NEW_ARRIVAL_STAMP = (Date.now() - NEW_ARRIVAL_THRESHOLD) / 1000
export const SALE_SLUG = process.env.NEXT_PUBLIC_SALE_SLUG ?? 'sale'
export const SALE_DISCOUNT_AMOUNT_THRESHOLD = process.env.NEXT_PUBLIC_SALE_DISCOUNT_AMOUNT_THRESHOLD ?? 5
export const SESSION_TIMEOUT_DURATION = 63 * 60 * 1000

//metaCapiURL
export const META_CAPI_EVENTS_URL_WITH_CONFIG_ID =
  process.env.NEXT_PUBLIC_META_CAPI_EVENTS_URL_WITH_CONFIG_ID ??
  'https://fb-capi.analytics.rlcdn.com/send-events/df1dd427-9ebe-4e77-bc02-62ac8a7274bd'
