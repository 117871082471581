import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Image, Text, CloseButton, Flex, Stack, useToken, Divider, Link } from '@chakra-ui/react'
import { LoyaltyPoints } from 'composable/components/loyalty-points'
import { getCartProductByVariantSkuFromAtgCart } from 'composable/helpers/utils/cart-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoImagesOutline } from 'react-icons/io5'
import { getInventoryOfProductBySku } from 'frontastic/actions/product'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { BackOrderMessage } from './back-order-message'
import { OutOfStockAlertMessage } from './out-of-stock-alert-message'
import { PriceDetails } from './price-details'
import { HorizontalProductCardProps } from './types'
import { cartlabels, cartOptionValues, evaluateBackorder, fetchAttributeData } from './utils'
import AtgForgotPassword from '../account/atg-forgot-password'
import AtgLoginPage from '../account/atg-login-page'
import { OptionsGetProduct } from '../general/components/options-get-product'
import { QuantityPicker } from '../quantity-picker'

export const HorizontalProductCardEditable = (props: HorizontalProductCardProps) => {
  const {
    columns = 3,
    size = 'lg',
    image,
    name,
    quantity,
    variant,
    onRemove,
    onChangeQuantity,
    isLoading,
    totalPriceCentAmount,
    url,
    masterProductData,
    optionsGetProduct,
    showGetProductOptions = false,
    changeSelectionGetProduct,
    editable,
    isDonationOrGiftCard,
    availabilityStatus,
  } = props

  const { formatMessage } = useFormat({ name: 'common' })
  const [brandSecondary] = useToken('colors', ['brand.secondary'])
  const { userSessionData } = useAtgUser()
  const { moveToWishList, formattedCart: cart, wishListData } = useAtgLegacyCart()
  const [availableQuantity, setAvailableQuantity] = useState<number | null>(null)
  const [backorderLevel, setBackOrderLevel] = useState<number | null>(null)
  const [ctAvailableQuantity, setCtAvailableQuantity] = useState<number | null>(null)
  const [isLoginModelOpen, setLoginModelOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

  const variantCountInCart = useMemo(
    () => getCartProductByVariantSkuFromAtgCart({ variantSku: variant?.sku, cart: cart }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant?.sku, cart?.lineItems],
  )

  const isOnWishList = !!wishListData?.find((item) => item === variant?.sku)

  // Fetch available quantity asynchronously
  const fetchAvailableQuantity = useCallback(async () => {
    const res = await getInventoryOfProductBySku(variant.sku)
    const ctAvailableQuantity = res.availableQuantity ?? 0
    setCtAvailableQuantity(ctAvailableQuantity)
    setBackOrderLevel(res.backorderlevel)

    if (variantCountInCart) {
      // availableQuantity is sent by PDP at the time of adding that item to cart
      // actualAvailableQuantity changes as user +/- item from the Cart, hence diverging from initial availableQuantity that was sent from PDP
      // Hence the actualAvailableQuantity is the qty available in the CT MINUS the qty of that sku in the cart
      setAvailableQuantity(ctAvailableQuantity - variantCountInCart)
    } else {
      setAvailableQuantity(ctAvailableQuantity)
    }
  }, [variant.sku, cart])

  useEffect(() => {
    fetchAvailableQuantity()
  }, [fetchAvailableQuantity])

  const { isBackordered, availabilityDate } = evaluateBackorder(availabilityStatus)

  const isInStock = ctAvailableQuantity >= variantCountInCart

  const isNotEnoughInStock =
    !!availableQuantity && availableQuantity > 0 && availableQuantity < quantity ? availableQuantity : false

  // Fetching dynamic styles and options based on props
  const { imageSize, quantityOptions, itemPriceOptions, detailsOptions, removeOptions, contentColumn } =
    cartOptionValues({ columns, size })

  // Fetching specific attributes and labels based on props
  const { imageSrc, imageAlt, parsedProductAttributes, productItemNumber, brand } = fetchAttributeData({
    image,
    masterProductData,
    variant,
    removedSegment3LabelWording: props?.removedSegment3LabelWording,
  })

  // Fetching localized labels for UI elements
  const labels = cartlabels(formatMessage)
  const removeButtonLabel = `${labels.remove} ${name}`

  // Configured QuantityPicker component for consistent usage
  const ConfiguredQuantityPicker = () => (
    <QuantityPicker
      min={1}
      max={availableQuantity > 0 ? availableQuantity : backorderLevel > 0 ? backorderLevel : 0}
      hideLabel
      rootProps={{ minWidth: '110px', maxW: 'fit-content', minHeight: 8, height: '40px' }}
      isLoading={isLoading}
      value={quantity}
      onChange={onChangeQuantity}
      quantityProps={{ minWidth: size === 'sm' ? 6 : 9 }}
      buttonProps={{ width: '16px', height: '16px', minWidth: '16px' }}
    />
  )

  useEffect(() => {
    callBackAfterLogin()
  }, [userSessionData?.firstName])

  const callBackAfterLogin = () => {
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
      handleMoveToWishlist()
    }
  }

  const closeLoginModal = (obj) => {
    if (obj?.close == 'byClick') {
      setLoginModelOpen(false)
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isLoginModelOpen) {
      closeLoginModal({})
    }
  }
  // Handling move to wishlist action based on user session
  const handleMoveToWishlist = async () => {
    if (isOnWishList) {
      return
    }

    if (userSessionData?.firstName) {
      await moveToWishList(
        variant?.attributes?.itemNumber,
        variant?.sku,
        quantity,
        variant?.attributes?.commerceItemId,
        name,
      )
    } else {
      setLoginModelOpen(true)
    }
  }

  return (
    <Box position="relative" marginBottom={0}>
      {/* Render remove button if onRemove function exists */}
      {removeOptions.type === 'icon' && onRemove && (
        <CloseButton
          aria-label={removeButtonLabel}
          size="3xl"
          isDisabled={isLoading}
          onClick={onRemove}
          position="absolute"
          top={0}
          right={2}
        />
      )}

      <Flex gap={4}>
        {/* Product image display */}
        <Flex
          aspectRatio={3 / 4}
          width={imageSize.width}
          height={imageSize.height}
          opacity={isNotEnoughInStock ? '0.6' : undefined}
        >
          <Link href={url || '#'}>
            {imageSrc ? (
              <Image src={imageSrc} alt={imageAlt} fit="contain" width="full" height="full" />
            ) : (
              <Flex justify="center" align="center" width="full" height="full" bgColor="brand.muted">
                <IoImagesOutline size="40px" color={brandSecondary} />
              </Flex>
            )}
          </Link>
        </Flex>
        <Flex flexDirection="column" width="100%">
          {/* Product details section */}
          <Flex {...contentColumn} grow={1}>
            <Flex direction="column" flex={1}>
              {/* Display brand and product name */}
              <Text fontSize="12px" fontWeight="400">
                {brand}
              </Text>
              <Link
                href={url || '#'}
                textStyle="body-100"
                _hover={{ textDecoration: 'underline', color: 'black' }}
                fontSize="14px"
                fontWeight={600}
              >
                {name}
              </Link>

              {/* Additional details and attributes */}
              <Box {...detailsOptions?.props} textStyle="body-50" style={{ wordWrap: 'break-word' }}>
                <Text marginTop="3px">
                  {productItemNumber
                    ? formatMessage({
                        id: 'checkout.success.orderSummary.skuLabel',
                        values: { itemsku: productItemNumber },
                      })
                    : ''}
                  , {parsedProductAttributes ?? ''}
                </Text>
                {!isDonationOrGiftCard && userSessionData?.dxlCountry === 'US' && (
                  <LoyaltyPoints
                    price={String(totalPriceCentAmount / 100)}
                    pdpOrCartDrawer="CART-DRAWER"
                    marginTop="10px"
                  />
                )}

                {/* Display item price details */}
                {itemPriceOptions.type === 'text' && (
                  <Box {...itemPriceOptions?.props}>
                    <PriceDetails {...props} />
                  </Box>
                )}

                {/* quantityOptions.type === 'text' is for cart drawer */}
                {quantityOptions.type === 'text' && (
                  <Box {...quantityOptions?.props}>
                    <ConfiguredQuantityPicker />
                  </Box>
                )}
              </Box>
            </Flex>

            {/* Display quantity picker in a stack for column layout */}
            {quantityOptions.type === 'column' && (
              <Stack {...quantityOptions?.props} flex="1">
                {quantityOptions.label && <Text textStyle="heading-mobile-75">{labels.quantity}</Text>}
                <ConfiguredQuantityPicker />
              </Stack>
            )}

            {/* itemPriceOptions.type === 'column' is for cart page */}
            {itemPriceOptions.type === 'column' && (
              <Stack {...itemPriceOptions?.props} flex="0.6">
                {itemPriceOptions.label && <Text textStyle="heading-mobile-75">{labels.itemPrice}</Text>}
                <PriceDetails {...props} />
              </Stack>
            )}
          </Flex>

          {/* Actions section */}
          <Box mt={2} marginLeft={quantityOptions.type === 'column' ? 'auto' : ''}>
            {/* removeOptions.type === 'text' is for cart drawer */}
            {!isDonationOrGiftCard && editable && itemPriceOptions.type == 'text' && (
              <Text
                as="button"
                textStyle="link-50"
                onClick={handleMoveToWishlist}
                cursor={isLoading || isOnWishList ? 'initial' : 'pointer'}
                aria-label={isOnWishList ? labels.alreadyAddedToWishList : labels.addToWishlist}
                color={isLoading || isOnWishList ? 'text-muted' : undefined}
              >
                {isOnWishList ? labels.alreadyAddedToWishList : labels.addToWishlist}
              </Text>
            )}

            {/* Render remove button */}
            {editable && onRemove && (
              <Text
                ml={2}
                as="button"
                textStyle="link-50"
                onClick={isLoading ? undefined : onRemove}
                cursor={isLoading ? 'initial' : 'pointer'}
                aria-label={removeButtonLabel}
                color={isLoading ? 'text-muted' : undefined}
                marginRight={quantityOptions.type === 'column' ? 'var(--chakra-space-4)' : ''}
              >
                {labels.remove}
              </Text>
            )}
          </Box>

          {/* quantityOptions.type === 'column' is for cart page */}
          {quantityOptions.type === 'column' && (
            <Box width={'100%'} marginTop={'var(--chakra-space-2)'}>
              <Divider />
            </Box>
          )}

          {editable && showGetProductOptions && (
            <OptionsGetProduct
              locationStore={optionsGetProduct?.locationStore}
              orderByTime={optionsGetProduct?.orderByTime}
              valueSelection={optionsGetProduct?.valueSelection}
              setSelection={changeSelectionGetProduct}
              isDisabled={isInStock}
            />
          )}

          {/* this is for cart page */}
          {isLoading && editable && quantityOptions.type === 'column' && !!availableQuantity && !isInStock && (
            <Flex marginRight="var(--chakra-space-4)">
              <OutOfStockAlertMessage {...props} />
            </Flex>
          )}
        </Flex>
      </Flex>

      {/* this is for cart drawer */}
      {!!availableQuantity && !isInStock && !isBackordered && !isDonationOrGiftCard && (
        <Flex>
          <OutOfStockAlertMessage {...props} />
        </Flex>
      )}
      {isBackordered && <BackOrderMessage />}
      <AtgLoginPage isOpen={isLoginModelOpen} onClose={closeLoginModal} toggleForgotPassword={toggleForgotPassword} />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
    </Box>
  )
}
