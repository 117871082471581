import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertDescriptionProps,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  CloseButton,
  CloseButtonProps,
  Flex,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'

interface AlertBoxProps {
  alertDescriptionProps?: AlertDescriptionProps
  closeButtonProps?: CloseButtonProps
  description?: string | JSX.Element
  onClick?: () => void
  rootProps?: AlertProps
  title?: string
}

export const AlertBox = (props: AlertBoxProps) => {
  const { alertDescriptionProps, closeButtonProps, description, onClick, rootProps, title } = props
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })
  const isMobile = useBreakpointValue({ base: true, md: false })

  return isVisible ? (
    <Alert status="info" borderRadius="6px" display="flex" justifyContent="space-between" {...rootProps}>
      <Flex>
        {rootProps?.status === 'error' ? (
          <WarningTwoIcon aria-hidden="true" w={6} h={6} color="danger.600" marginInlineEnd={0} />
        ) : (
          <AlertIcon aria-hidden="true" w={6} h={6} marginInlineEnd={0} />
        )}
        <Box>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription
            textStyle={isMobile ? 'heading-mobile-75' : 'heading-desktop-75'}
            onClick={() => onClick?.()}
            {...alertDescriptionProps}
          >
            {description}
          </AlertDescription>
        </Box>
      </Flex>
      <CloseButton alignSelf="center" position="relative" onClick={onClose} {...closeButtonProps} />
    </Alert>
  ) : null
}
