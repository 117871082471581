import atgAxiosInstance from 'helpers/axios-atg-helper'

interface StoreHours {
  day: string
  open: string
  close: string
}

export const phoneMask = (value) => {
  if (value && value.length) {
    let code = value.slice(0, 1)
    let city = value.slice(2, 5)
    let number = value.slice(5)
    number = number.slice(0, 3) + '-' + number.slice(3)
    return (' (' + city + ') ' + number).trim()
  } else {
    return value
  }
}

export const storesDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (angle) => angle * (Math.PI / 180)
  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const radius = 3958.8
  const distance = radius * c

  return distance
}

let weeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const formatTime = (timeString) => {
  if (timeString && timeString != undefined) {
    const [hourString, minute] = timeString.split(':')
    const hour = +hourString % 24
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
  }
}
export const getTodayTiming = (time) => {
  let timing = time
  let todayStoreTiming = ''
  let today = new Date()
  if (timing && timing != undefined && timing.length && timing.length > 0) {
    for (let i = 0; i < timing.length; i++) {
      if (timing[i].indexOf('Closed') > -1 || timing[i].indexOf('closed') > -1) {
        let splitString = timing[i].split(':')
        let day = splitString[0]
        if (weeks[today.getDay()].indexOf(day) > -1) {
          todayStoreTiming = '<div style="color:#C42D3A;font-weight:700">Closed</div>'
        }
      } else {
        let splitString = timing[i].split('||')
        if (splitString[1] != 'Closed') {
          let day = splitString[0]
          let startTime = splitString[1]
          let endTime = splitString[2]
          startTime = formatTime(startTime)
          endTime = formatTime(endTime)
          if (weeks[today.getDay()].indexOf(day) > -1) {
            todayStoreTiming =
              '<div tabindex="0" aria-label="' +
              startTime +
              '-' +
              endTime +
              '"><span style="color:#38A169;font-weight:700;margin-right:8px;">Open</span>' +
              startTime +
              ' - ' +
              endTime +
              '</div>'
          }
        } else {
          todayStoreTiming = '<div style="color:#C42D3A;font-weight:700">Closed</div>'
        }
      }
    }
  }
  return todayStoreTiming
}
export const filterTime = (time) => {
  let timing = time
  let timingHtml = ''
  let day_of_week = new Date().getDay()
  for (let row of timing) {
    if (row.indexOf('Closed') > -1 || row.indexOf('closed') > -1) {
      let splitCLoseDay = row.split(':')
      let day = splitCLoseDay[0]
      if (weeks[day_of_week].indexOf(day) > -1) {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" style="margin-bottom:12px;" class="flex" aria-label="' +
          splitCLoseDay[0] +
          '' +
          splitCLoseDay[1] +
          '"><div class="capitalize" style="text-transform: capitalize;width:40%;font-weight:500" >' +
          splitCLoseDay[0] +
          '</div><div style="width:60%;font-weight:500">' +
          splitCLoseDay[1] +
          '</div></div>'
      } else {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" style="margin-bottom:12px;" class="flex" aria-label="' +
          splitCLoseDay[0] +
          '' +
          splitCLoseDay[1] +
          '"><div class="capitalize" style="text-transform: capitalize;width:40%" >' +
          splitCLoseDay[0] +
          '</div><div style="width:60%">' +
          splitCLoseDay[1] +
          '</div></div>'
      }
    } else {
      let splitString = row.split('||')
      let day = splitString[0]
      let startTime = splitString[1]
      let endTime = splitString[2]
      startTime = formatTime(startTime)
      endTime = formatTime(endTime)
      if (weeks[day_of_week].indexOf(day) > -1) {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" style="margin-bottom:12px;" class="flex" aria-label="' +
          day +
          '' +
          startTime +
          '-' +
          endTime +
          '"><div class="capitalize" style="text-transform: capitalize;width:40%;font-weight:500">' +
          day +
          '</div><div style="width:60%;font-weight:500">' +
          startTime +
          ' - ' +
          endTime +
          '</div></div>'
      } else {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" style="margin-bottom:12px;" class="flex" aria-label="' +
          day +
          '' +
          startTime +
          ' - ' +
          endTime +
          '"><div class="textCapitalize" style="text-transform: capitalize;width:40%">' +
          day +
          '</div><div style="width:60%">' +
          startTime +
          ' - ' +
          endTime +
          '</div></div>'
      }
    }
  }

  return timingHtml
}
export const getCookieByName = (name) => {
  const cookies = document?.cookie?.split('; ')
  for (let i = 0; i < cookies?.length; i++) {
    const cookie = cookies[i].split('=')
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1])
    }
  }

  return null
}

export const savedStoreToAtgSession = async (selectedStore, fetchUserSessionData) => {
  await atgAxiosInstance.post('/currentUser/myAccount/saveStore', {
    storeId: selectedStore.storeId,
  })
  fetchUserSessionData()
}

const parseStoreHours = (hours: string[]): StoreHours[] =>
  hours.map((h) => {
    const [day, open, close] = h.split('||')
    return { day, open, close }
  })

export const getStoreTimingMessage = (storeHoursInput: string[]): { isAvailableToday: boolean } => {
  const currentDate = new Date()
  const storeHours = parseStoreHours(storeHoursInput)
  const currentDay = currentDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase()
  const currentHour = currentDate.getHours()
  const currentMinutes = currentDate.getMinutes()

  const todayHours = storeHours.find((h) => h.day === currentDay)
  if (!todayHours) {
    return { isAvailableToday: false }
  }

  const [openHour, openMinutes] = todayHours.open.split(':').map(Number)
  const [closeHour, closeMinutes] = todayHours.close.split(':').map(Number)
  const storeOpenTime = new Date(currentDate)
  storeOpenTime.setHours(openHour, openMinutes)
  const storeCloseTime = new Date(currentDate)
  storeCloseTime.setHours(closeHour, closeMinutes)

  if (currentDate < storeOpenTime || currentDate > storeCloseTime) {
    return { isAvailableToday: false }
  }

  if (currentHour < 14 || (currentHour === 14 && currentMinutes === 0)) {
    return { isAvailableToday: true }
  }
  return { isAvailableToday: false }
}
