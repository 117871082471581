import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import AtgForgotPassword from 'composable/components/account/atg-forgot-password'
import { APP_VERSION } from 'composable/components/general/constants'
import { utagLink } from 'helpers/tealiumHelper'
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import { useAtgUser, useAtgLegacyCart } from 'frontastic/contexts'
import AtgLoginPage from '../../../composable/components/account/atg-login-page'
import { CartDrawerEmptyState } from '../../../composable/components/cart/cart-drawer/cart-drawer-empty-state'
import { useComposable } from '../../../composable/components/composable-provider'
import { calculateRewardPoints, formatSaleMessage } from '../../utils/legacy-cart'
import { LegacyHorizontalProductCard } from '../legacy-horizontal-product-card'

export interface CartDrawerDiscountDetails {
  [key: string]: string
}

export const LegacyCartDrawer = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const { userSessionData, fetchUserSessionData } = useAtgUser()
  const { cartData, removeItem, updateItem, refreshCart, moveToWishList } = useAtgLegacyCart()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [discountCodes, setDiscountCodes] = useState<CartDrawerDiscountDetails>()
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

  useEffect(() => {
    if (userSessionData?.firstName) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [userSessionData])

  const handleUpdate = async (updatePromise: Promise<any>, itemId?: any) => {
    const item = cartData.items.find((item) => item.id === itemId)
    setIsUpdating(true)
    try {
      await updatePromise
    } catch (err) {
      console.log(err)
    } finally {
      refreshCart() // Only Recalculate the Cart after the update is done.
      fetchUserSessionData()
      if (itemId) {
        const data = [
          'link',
          {
            tealium_event: 'cart_remove',
            product_id: [item?.productId],
            product_name: [item.additionalLineItemAttributes?.commerceItem.auxiliaryData?.productRef?.description],
            product_brand: [item.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.productRef?.brand],
            product_price: [item.additionalLineItemAttributes?.commerceItem?.priceInfo?.amount?.toString()],
            product_quantity: [item.quantity.toString()],
            product_sku: [item.sku?.id],
            product_variant: [item.sku?.colorName],
            product_category: [
              item.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.productRef?.parentCategory
                ? item.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.productRef?.parentCategory
                    ?.displayName
                : '',
            ],
            product_color: [item.sku?.colorName],
            order_grand_total: [cartData?.totals?.convAmount],
          },
        ]
        utagLink(data)
      }
    }
    setIsUpdating(false)
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isModalOpen) {
      closeModal()
    }
  }

  const closeModal = () => setIsModalOpen(false)
  return (
    <Drawer isOpen={cartDrawer.isOpen} placement="right" onClose={cartDrawer.onClose} size={'sm'}>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 375, md: 400 }}>
        <DrawerHeader display="flex" p={'12px 16px'} flexDirection="column">
          <Box display="flex">
            <Box display="flex" flex={1} justifyContent="flex-start">
              <Text
                textStyle={'heading-desktop-200'}
                fontSize={{ base: '1.25rem' }}
                fontWeight={500}
                lineHeight={'1.5rem'}
                tabIndex={0}
              >
                Shopping Bag ({userSessionData?.noOfCommerceItems || 0})
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <DrawerCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                borderRadius="100%"
                bgColor="black"
                color="white"
                size={'sm'}
                fontSize={'9px'}
              />
            </Box>
          </Box>

          {cartData?.items && (
            <>
              <Box display="flex" mt={{ base: 5 }} justifyContent="space-between">
                <Text textStyle="body-100" fontWeight={600}>
                  {formatMessage({ id: 'cart.summary.subtotal' })}
                </Text>
                <Text textStyle="body-75" fontWeight={600}>
                  {CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                    cartData?.totals?.convAmount * 100,
                    userSessionData?.dxlCurrency,
                  )}
                </Text>
              </Box>

              <Box display="flex" my={{ base: 2 }}>
                <Button
                  onClick={async () => {
                    utagLink([
                      'link',
                      {
                        tealium_event: 'button_click_view_bag_and_checkout',
                      },
                    ])

                    if (APP_VERSION === 'R1') {
                      window.location.assign('/cart/cart')
                    } else {
                      router.push('/cart')
                    }

                    cartDrawer.onClose()
                  }}
                  variant={'solid'}
                  textStyle={{ base: 'ui-button-75' }}
                  width="100%"
                  height={{ base: 8, md: 12 }}
                  maxHeight="40px"
                  borderRadius={0}
                >
                  View Bag and Checkout
                </Button>
              </Box>
            </>
          )}
        </DrawerHeader>

        <DrawerBody>
          {!cartData || !cartData.items ? (
            <CartDrawerEmptyState onClose={cartDrawer.onClose} />
          ) : (
            <Stack spacing={2}>
              <List>
                <Stack spacing={2}>
                  {cartData?.items?.map((item) => {
                    const {
                      additionalLineItemAttributes: {
                        commerceItemId: id,
                        relationShipId,
                        inStock,
                        commerceItem: {
                          webURL: _url,
                          priceInfo,
                          commerceItemClassType,
                          customizedItemAmount,
                          auxiliaryData: {
                            productId,
                            catalogRef: { imageColorCode, id: skuId, colorName },
                            productRef: { displayName, brand, description },
                          },
                        },
                        sizes,
                      },
                    } = item

                    const variant = {
                      isOnStock: inStock,
                      availableQuantity: 99,
                      sku: skuId,
                      prices: [],
                      attributes: {
                        segment2: sizes.join(', '),
                        ecommColor: colorName,
                      },
                    }
                    const masterProductData = [
                      {
                        name: 'brand',
                        value: brand,
                      },
                      {
                        name: 'segment2Label',
                        value: 'Size',
                      },
                      {
                        name: 'ecommColor',
                        value: 'Color',
                      },
                    ]

                    const hasAdjustment =
                      priceInfo?.adjustments?.length > 1 && priceInfo?.adjustments[1]?.convTotalAdjustment !== 0
                    const listPriceTotalValue = priceInfo?.adjustments[0]?.convTotalAdjustment
                    const listPriceQuantity = priceInfo?.adjustments[0]?.quantityAdjusted

                    const salePriceTotalValue = priceInfo?.adjustments[1]?.convTotalAdjustment
                    const salePriceQuantity = priceInfo?.adjustments[1]?.quantityAdjusted

                    const totalPrice = priceInfo?.convAmount
                    const unitPrice = priceInfo?.conUnitAmount
                    const listPrice = priceInfo?.convListPrice
                    const count = hasAdjustment ? salePriceQuantity : listPriceQuantity

                    const isCustomizedItem = commerceItemClassType === 'customizationCommerceItem'
                    const isDonationOrGiftCard = commerceItemClassType === 'giftCardCommerceItem'

                    const totalDiscountedPrice = isCustomizedItem
                      ? Number(priceInfo.convListPrice - priceInfo.conUnitAmount) * count
                      : hasAdjustment
                        ? salePriceTotalValue < 0
                          ? Number(salePriceTotalValue * -1)
                          : Number(listPriceTotalValue - salePriceTotalValue)
                        : undefined

                    const hasRewardsPointsToDisplay = userSessionData?.dxlCountry === 'US'

                    const itemAmountToBeUsedInRewards = isCustomizedItem
                      ? customizedItemAmount
                      : commerceItemClassType != 'customizationCommerceItem'
                        ? priceInfo?.amount
                        : 0
                    const rewardPoints = hasRewardsPointsToDisplay
                      ? calculateRewardPoints(itemAmountToBeUsedInRewards, userSessionData?.currentTierPurchaseRatio)
                      : 0

                    const customizationCommerceItem = isCustomizedItem
                      ? priceInfo?.adjustments.find(
                          (item) => item.adjustmentDescription === 'Customization Item Adjustment',
                        )
                      : null

                    const customizationCommerceItemMsg = customizationCommerceItem?.customChargeInfo
                      ? `${customizationCommerceItem.customChargeInfo
                          .split(',')
                          .join(', ')} : ${CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                          customizationCommerceItem.convTotalAdjustment * 100,
                          userSessionData?.dxlCurrency,
                        )}`
                      : null

                    const imageSrc =
                      item?.commerceItemClassType === 'giftCardCommerceItem'
                        ? `//images.dxl.com/is/image/CasualMale/p${productId}`
                        : `//images.dxl.com/is/image/CasualMale/p${productId}${imageColorCode}?$product$`

                    const saleMessage = formatSaleMessage({
                      priceInfo,
                      currencyCode: userSessionData?.dxlCurrency,
                    })

                    return (
                      <ListItem key={productId}>
                        <LegacyHorizontalProductCard
                          allCartLevelPromotions={discountCodes}
                          isDonationOrGiftCard={isDonationOrGiftCard}
                          url={_url}
                          editable
                          columns={3}
                          size="sm"
                          image={{
                            src: imageSrc,
                            alt: description ?? '',
                            onClickImage: async () => {
                              window.location.assign(_url)
                              cartDrawer.onClose()
                            },
                          }}
                          name={description || displayName || ''}
                          quantity={count}
                          quantityPickerProps={{
                            max: variant?.availableQuantity ?? 99,
                          }}
                          priceCentAmount={totalPrice * 100}
                          price={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            totalPrice * 100,
                            userSessionData?.dxlCurrency,
                          )}
                          discountedPrice={
                            hasAdjustment
                              ? CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                                  listPrice * 100,
                                  userSessionData?.dxlCurrency,
                                )
                              : undefined
                          }
                          individualUnitPrice={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            totalPrice * 100,
                            userSessionData?.dxlCurrency,
                          )}
                          totalPriceCentAmount={totalPrice * 100}
                          totalPrice={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            unitPrice * 100,
                            userSessionData?.dxlCurrency,
                          )}
                          totalDiscountedPrice={
                            totalDiscountedPrice && totalDiscountedPrice > 0
                              ? CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                                  totalDiscountedPrice * 100,
                                  userSessionData?.dxlCurrency,
                                )
                              : undefined
                          }
                          // @ts-ignore
                          variant={variant}
                          onRemove={() => handleUpdate(removeItem(`${id}:${relationShipId}`), id)}
                          onChangeQuantity={async (val) => {
                            val === 0
                              ? await handleUpdate(removeItem(id))
                              : await handleUpdate(updateItem(id, val, description || displayName || ''))
                          }}
                          onAddToWishlist={async () => {
                            if (!isLoggedIn) {
                              return setIsModalOpen(true)
                            }
                            await handleUpdate(
                              moveToWishList(productId, skuId, count, cartData.items, description || displayName || ''),
                            )
                          }}
                          isLoading={isUpdating}
                          displayOutOfStock={true}
                          masterProductData={masterProductData}
                          rewardPoints={hasRewardsPointsToDisplay && !isDonationOrGiftCard ? rewardPoints : undefined}
                          customizationCommerceItem={customizationCommerceItemMsg}
                          dxlSaleMessage={saleMessage}
                        />
                      </ListItem>
                    )
                  })}
                </Stack>
              </List>
            </Stack>
          )}
        </DrawerBody>
      </DrawerContent>
      <AtgLoginPage isOpen={isModalOpen} onClose={closeModal} toggleForgotPassword={toggleForgotPassword} />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
    </Drawer>
  )
}
