import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
export const parseAttributes = (productAttributes: Array<{ name: string; value: string }>) => {
  if (!productAttributes) return ''

  return productAttributes
    ?.map((item, index) => {
      const { name, value } = item

      if (!name || !value) return '' // When it is a gift card or donation, these values are empty

      const formattedKey = name.charAt(0).toUpperCase() + name.slice(1)
      const formattedValue =
        name === 'color' ? value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : value

      return `<b>${formattedKey}:</b> ${formattedValue}<br />`
    })
    .join('')
}

export const calculateRewardPoints = (price: number, ratio: number | string) => {
  return Math.round(Number(price) * Number(ratio))
}

export const capitalizeFirstLetter = (string: string | undefined) =>
  string ? string[0].toUpperCase() + string.slice(1) : undefined

// Adapted method from DXL
export const formatSaleMessage = ({ priceInfo, currencyCode }: { priceInfo: any; currencyCode: string }) => {
  let saleMessage = ''
  let saleprice = ''
  if (priceInfo) {
    if (priceInfo.convRawTotalPrice > priceInfo.convAmount) {
      if (priceInfo.currentPriceDetailsSorted[0].prorated) {
        let proratedPrice: number = 0
        for (let i = 0; i < priceInfo.currentPriceDetailsSorted.length; i++) {
          if (priceInfo.currentPriceDetailsSorted[i].prorated) {
            proratedPrice += priceInfo.currentPriceDetailsSorted[i].convProratedAmount
          } else {
            proratedPrice +=
              priceInfo.currentPriceDetailsSorted[i].convProratedUnitPrice *
              priceInfo.currentPriceDetailsSorted[i].quantity
          }
        }
        saleprice = (priceInfo.convRawTotalPrice - proratedPrice).toFixed(2)
      } else {
        saleprice = (priceInfo.convRawTotalPrice - priceInfo.convAmount).toFixed(2)
      }

      let formattedPrice = CurrencyHelpersWithoutBorderConversion.formatForCurrency(
        Number(saleprice) * 100,
        currencyCode,
      )
      saleMessage += 'You Saved ' + formattedPrice
    }
    let checksaleBulkMsgCount = 0
    let checkItemDiscountCount = 0
    if (priceInfo.adjustments && priceInfo.adjustments.length > 0) {
      for (let i = 0; i < priceInfo.adjustments.length; i++) {
        if (
          priceInfo.adjustments[i].adjustmentDescription &&
          priceInfo.adjustments[i].adjustmentDescription.toLowerCase() === 'sale bulk price'
        ) {
          checksaleBulkMsgCount++
          if (typeof priceInfo.adjustments[i].convNextTierSaleMessage !== 'undefined') {
            if (saleMessage !== '') {
              saleMessage += '<br>' + priceInfo.adjustments[i].convNextTierSaleMessage
            } else {
              saleMessage += priceInfo.adjustments[i].convNextTierSaleMessage
            }
          }
        }
        if (
          priceInfo.adjustments[i].adjustmentDescription &&
          priceInfo.adjustments[i].adjustmentDescription === 'Item Discount'
        ) {
          if (priceInfo.adjustments[i].convPromoName !== '') {
            checkItemDiscountCount++
            if (saleMessage !== '') {
              saleMessage += '<br>' + priceInfo.adjustments[i].convPromoName
            } else {
              saleMessage += priceInfo.adjustments[i].convPromoName
            }
          }
        }
      }
    }
    if (saleprice !== '' && checkItemDiscountCount == 0 && checksaleBulkMsgCount == 0) {
      if (priceInfo.currentPriceDetailsSorted[0].promoName) {
        saleMessage += '<br>' + priceInfo.currentPriceDetailsSorted[0].promoName
      }
      return saleMessage
    }
    if (checkItemDiscountCount == 0 && checksaleBulkMsgCount == 0) {
      for (let i = 0; i < priceInfo.currentPriceDetailsSorted.length; i++) {
        if (priceInfo.currentPriceDetailsSorted[i].promoName) {
          const salePriceRefined =
            priceInfo.convAmount -
            priceInfo.currentPriceDetailsSorted[i].convProratedUnitPrice *
              priceInfo.currentPriceDetailsSorted[i].quantity
          const formattedPrice2 = currencyCode + salePriceRefined
          saleMessage += 'You Saved ' + formattedPrice2
          saleMessage += '<br>' + priceInfo.currentPriceDetailsSorted[i].promoName
        }
      }
    }
  }
  return saleMessage
}
