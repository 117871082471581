import React, { createRef } from 'react'
import { Button } from '@chakra-ui/react'
import { CartIcon } from 'composable/components/cart-icon'
import { useFormat } from 'helpers/hooks/useFormat'
import { useAtgLegacyCart } from 'frontastic/contexts'

export const refCartButton = createRef<HTMLButtonElement>()

export const CartButton = ({ verticalButtonProps, isCartPage, cartQuantity = 0, onOpenCartDrawer }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { cartData } = useAtgLegacyCart()

  return (
    <Button
      ref={refCartButton}
      id={'my-shopping-bag'}
      title={formatMessage({ id: 'header.cart.title' })}
      variant="ghost"
      name={'my shopping bag'}
      onClick={() => !isCartPage && onOpenCartDrawer?.()}
      opacity={isCartPage ? 0.5 : 1}
      disabled={isCartPage}
      cursor={isCartPage ? 'not-allowed' : 'pointer'}
      aria-label={`${cartQuantity} items in your shopping bag`}
      {...verticalButtonProps}
      tabIndex={0}
      size="md"
    >
      <CartIcon cartQuantity={cartData?.itemsInCart ?? 0} />
    </Button>
  )
}
