import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
  Show,
  Button,
  HStack,
  Link,
  Box,
  ListItem,
  List,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react'
import { EarnIcon } from 'atg/icons/earn-icon'
import { EngageIcon } from 'atg/icons/engage-icon'
import { EnjoyIcon } from 'atg/icons/enjoy-icon'
import { RepeatIcon } from 'atg/icons/repeat-icon'
import { NextLinkNoPrefetch } from 'composable/components/link'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import { useAccount } from 'frontastic'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import SignUpModal from 'frontastic/tastics/SignupModal'
import AtgForgotPassword from './atg-forgot-password'
import AtgLoginPage from './atg-login-page'
import { ForgotPasswordForm } from './forgot-password-form'
import { LoginForm } from './login-form'
import OrderStatusModal from './pages/account-dashboard/components/order-status'
import { RegisterForm } from './register-form'
import { useComposable } from '../composable-provider'
import { APP_VERSION } from '../general/constants'
import { SidebarNav } from '../sidebar/sidebar-nav'

export enum AccountPageType {
  PAGE = 'page',
  DRAWER = 'drawer',
}

export type AccountFormTypes = 'login' | 'forgot_password' | 'create_account'

export const AccountDrawer = () => {
  const isLegacyDrawer = APP_VERSION === 'R1' || APP_VERSION === 'R2'
  const router = useRouter()
  const { account } = useAccount()
  const { accountDrawer } = useComposable()
  const [accountFormToShow, setAccountFormToShow] = useState<AccountFormTypes>('login')
  const { formatMessage } = useFormat({ name: 'common' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { userSessionData, fetchUserSessionData, fetchUserData } = useAtgUser()
  const { refreshCart } = useAtgLegacyCart()
  const [isLoggedIn, setIsLoggedIn] = useState(!!userSessionData?.firstName)
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)
  const isMobile = useBreakpointValue({ base: true, lg: false })
  useEffect(() => {
    setIsLoggedIn(!!userSessionData?.firstName)
  }, [userSessionData])

  const logoutAtgUser = async () => {
    try {
      await atgAxiosInstance.post('currentUser/logout', {})
      setIsLoggedIn(false)
    } catch (e) {
      console.error('Error logging out the user', e)
    }
  }

  const handleLogout = async () => {
    await logoutAtgUser()
    fetchUserSessionData()
    fetchUserData()
    refreshCart()
    utagLink(
      [
        'link',
        {
          tealium_event: 'signout',
          crm_customer_number: null,
          customer_id: null,
          user_loginstatus: false,
        },
      ],
      userSessionData?.dxlCountry,
      userSessionData?.dxlCurrency,
    )
    router.push('/')
    accountDrawer.onClose()
  }

  const openLoginModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleSignIn = () => {
    accountDrawer.onClose()
    openLoginModal()
  }

  const handleSignUpModalToggle = () => {
    if (!isOpenSignUpModal) {
      accountDrawer.onClose()
    }
    setIsOpenSignUpModal(!isOpenSignUpModal)
  }
  const closeOrderStatusModal = () => {
    setIsOrderStatusModalOpen(false)
  }
  if (isOrderStatusModalOpen) {
    return <OrderStatusModal isOpen={isOrderStatusModalOpen} onClose={closeOrderStatusModal} />
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isModalOpen) {
      closeModal()
    }
  }

  const DrawerWrapper = ({ accountContent, signInContent }) => (
    <Drawer isOpen={accountDrawer.isOpen} onClose={accountDrawer.onClose} placement="right" size={'md'}>
      <DrawerOverlay />
      {account ? accountContent : signInContent}
    </Drawer>
  )
  // Create a distinct drawer to be used with legacy header (First Release - R1)
  if (isLegacyDrawer) {
    return (
      <>
        <DrawerWrapper
          accountContent={<></>}
          signInContent={
            <DrawerContent maxW={'400px'} padding={0}>
              <DrawerHeader
                minH={'48px'}
                p={'8px 12px'}
                display={'flex'}
                borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
              >
                <DrawerCloseButton
                  borderRadius="100%"
                  bgColor="black"
                  color="white"
                  size={'sm'}
                  fontSize={'9px'}
                  right="16px"
                />

                <Text fontSize="3xl" fontWeight={600} pt={2} tabIndex={0}>
                  Welcome{isLoggedIn ? `, ${userSessionData?.firstName}` : ''}!
                </Text>
              </DrawerHeader>
              <DrawerBody px="0">
                {isLoggedIn ? (
                  <Box>
                    <List spacing={3}>
                      <ListItem _hover={{ bg: '#f5f5f5' }} padding={4} height={12} mt={'0 !important'} cursor="pointer">
                        <Link
                          _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                          fontSize={'14px'}
                          textDecoration={'none'}
                          href="/myaccount/rewards/benefits"
                          title="DXL Rewards Club"
                          tabIndex={0}
                        >
                          DXL Rewards Club
                        </Link>
                      </ListItem>
                      {isMobile && (
                        <ListItem
                          _hover={{ bg: '#f5f5f5' }}
                          padding={4}
                          height={12}
                          mt={'0 !important'}
                          cursor="pointer"
                        >
                          <Link
                            _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                            fontSize={'14px'}
                            href="/myaccount/myOrderHistory"
                            textDecoration={'none'}
                          >
                            {' '}
                            Order History{' '}
                          </Link>
                        </ListItem>
                      )}
                      <ListItem _hover={{ bg: '#f5f5f5' }} padding={4} height={12} mt={'0 !important'} cursor="pointer">
                        <Link
                          _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                          fontSize={'14px'}
                          href="/myaccount/profile"
                          textDecoration={'none'}
                          title="My Account"
                          tabIndex={0}
                        >
                          {isMobile ? 'My Profile' : 'My Account'}
                        </Link>
                      </ListItem>
                      {isMobile && (
                        <ListItem
                          _hover={{ bg: '#f5f5f5' }}
                          padding={4}
                          height={12}
                          mt={'0 !important'}
                          cursor="pointer"
                        >
                          <Link
                            _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                            fontSize={'14px'}
                            href="/myaccount/address"
                            textDecoration={'none'}
                          >
                            {' '}
                            Saved Addresses{' '}
                          </Link>
                        </ListItem>
                      )}
                      {isMobile && (
                        <ListItem
                          _hover={{ bg: '#f5f5f5' }}
                          padding={4}
                          height={12}
                          mt={'0 !important'}
                          cursor="pointer"
                        >
                          <Link
                            _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                            fontSize={'14px'}
                            href="/myaccount/myPayments"
                            textDecoration={'none'}
                          >
                            {' '}
                            Saved Credit Cards{' '}
                          </Link>
                        </ListItem>
                      )}
                      <ListItem _hover={{ bg: '#f5f5f5' }} padding={4} height={12} mt={'0 !important'} cursor="pointer">
                        <Link
                          _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                          fontSize={'14px'}
                          href="/myaccount/wishlist"
                          textDecoration={'none'}
                          title="Wishlist"
                          tabIndex={0}
                        >
                          Wishlist
                        </Link>
                      </ListItem>
                      <ListItem _hover={{ bg: '#f5f5f5' }} padding={4} height={12} mt={'0 !important'} cursor="pointer">
                        <Link
                          _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                          fontSize={'14px'}
                          onClick={() => {
                            accountDrawer.onClose()
                            setIsOrderStatusModalOpen(true)
                          }}
                          textDecoration={'none'}
                          title="Order Status"
                          tabIndex={0}
                          href="#"
                        >
                          Order Status
                        </Link>
                      </ListItem>
                      <ListItem _hover={{ bg: '#f5f5f5' }} padding={4} height={12} mt={'0 !important'} cursor="pointer">
                        <Link
                          _hover={{ bg: '#f5f5f5', color: 'black', textDecoration: 'none' }}
                          fontSize={'14px'}
                          textDecoration={'none'}
                          onClick={() => handleLogout()}
                          title="Sign Out"
                          href="#"
                          tabIndex={0}
                        >
                          Sign Out
                        </Link>
                      </ListItem>
                    </List>

                    <Divider borderColor={'#5f8db5'} m={4} width={'auto'} />
                    <Center width={'full'} display="flex" minH="48px" my={4}>
                      <Text
                        fontSize="lg"
                        fontFamily={'Antonio'}
                        textTransform={'uppercase'}
                        color="#e3b857"
                        fontWeight={700}
                      >
                        Are you getting all you deserve?
                      </Text>
                    </Center>
                    <List spacing={3}>
                      <ListItem
                        display={'flex'}
                        gap={4}
                        alignItems={'center'}
                        padding={4}
                        height={12}
                        mt={'0 !important'}
                        cursor="pointer"
                      >
                        <EarnIcon fontSize="2rem" color="#5f8db5" />
                        <Link
                          _hover={{ color: 'black', textDecoration: 'none' }}
                          fontSize="sm"
                          fontWeight={400}
                          href="/"
                          textDecoration={'none'}
                        >
                          Earn points for every purchase!
                        </Link>
                      </ListItem>
                      <ListItem
                        display={'flex'}
                        gap={4}
                        alignItems={'center'}
                        padding={4}
                        height={12}
                        mt={'0 !important'}
                        cursor="pointer"
                      >
                        <EnjoyIcon fontSize="1.8rem" color="#5f8db5" />
                        <Link
                          _hover={{ color: 'black', textDecoration: 'none' }}
                          fontSize="sm"
                          fontWeight={400}
                          href="/"
                          textDecoration={'none'}
                        >
                          Redeem Rewards with no brand exclusions
                        </Link>
                      </ListItem>
                    </List>
                    <Box p={4}>
                      <Button
                        minH="48px"
                        as={Link}
                        fontSize="14px"
                        fontWeight={400}
                        width={'full'}
                        borderRadius={0}
                        href="/myaccount/rewards/benefits"
                        tabIndex={0}
                      >
                        DXL Rewards Club
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box px={4}>
                    <Center width={'full'} display="flex" minH="48px" mb={2}>
                      <Text fontSize="11px" fontWeight={600} tabIndex={0}>
                        Already have any account?
                      </Text>
                    </Center>
                    <Button
                      minH="48px"
                      fontSize="14px"
                      fontWeight={400}
                      width={'full'}
                      borderRadius={0}
                      onClick={() => {
                        handleSignIn()
                      }}
                    >
                      Sign In
                    </Button>
                    <Center width={'full'} display="flex" minH="48px" my={4}>
                      <Text
                        fontSize="lg"
                        fontFamily={'Antonio'}
                        textTransform={'uppercase'}
                        color="#e3b857"
                        fontWeight={700}
                        tabIndex={0}
                      >
                        Join our rewards club today
                      </Text>
                    </Center>
                    <VStack gap={4}>
                      <HStack width="100%" gap={4}>
                        <EngageIcon fontSize="2rem" color="#5f8db5" />
                        <Text fontSize="sm" fontWeight={400} tabIndex={0}>
                          It&apos;s easy and fun to earn Rewards points
                        </Text>
                      </HStack>
                      <HStack width="100%" gap={4}>
                        <EarnIcon fontSize="2rem" color="#5f8db5" />
                        <Text fontSize="sm" fontWeight={400} tabIndex={0}>
                          Earn Reward points for every purchase no matter how you shop
                        </Text>
                      </HStack>
                      <HStack width="100%" gap={4}>
                        <EnjoyIcon fontSize="1.8rem" color="#5f8db5" />
                        <Text fontSize="sm" fontWeight={400} tabIndex={0}>
                          Redeem Rewards with no brand exclusions
                        </Text>
                      </HStack>
                      <HStack width="100%" gap={4}>
                        <RepeatIcon fontSize="1.8rem" color="#5f8db5" />
                        <Text fontSize="sm" fontWeight={400} tabIndex={0}>
                          Keep those points coming. The more you keep in touch, the more you earn!
                        </Text>
                      </HStack>
                    </VStack>
                    <Button
                      minH="48px"
                      fontSize="14px"
                      fontWeight={400}
                      variant="outline"
                      width={'full'}
                      my={4}
                      borderRadius={0}
                      onClick={handleSignUpModalToggle}
                    >
                      Register
                    </Button>
                    <Link
                      as={NextLinkNoPrefetch}
                      _hover={{ color: '#6b6b6b', textDecoration: 'none' }}
                      fontSize="14px"
                      href="#"
                      onClick={() => {
                        accountDrawer.onClose()
                        setIsOrderStatusModalOpen(true)
                      }}
                    >
                      Order Status
                    </Link>
                  </Box>
                )}
              </DrawerBody>
            </DrawerContent>
          }
        />
        <SignUpModal
          onOpen={handleSignUpModalToggle}
          onClose={handleSignUpModalToggle}
          isOpen={isOpenSignUpModal}
          openSignInModal={openLoginModal}
        />
        <AtgLoginPage isOpen={isModalOpen} onClose={closeModal} toggleForgotPassword={toggleForgotPassword} />
        <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
      </>
    )
  }

  const title = formatMessage({ id: 'account.login.title' })

  return (
    <DrawerWrapper
      accountContent={
        <DrawerContent maxW={'550px'} padding={0}>
          <DrawerHeader
            minH={'48px'}
            p={'8px 12px'}
            display={'flex'}
            borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
          >
            <DrawerCloseButton size={'md'} fontSize={'sm'} left={{ base: 'initial', md: '10px' }} />
            <Center width={'full'} display={{ base: 'flex', md: 'none' }}>
              <Text textStyle={'heading-desktop-200'}>{formatMessage({ id: 'account.dashboard.title' })}</Text>
            </Center>
          </DrawerHeader>
          <DrawerBody p={{ base: '24px 16px', md: '24px 64px 60px 64px' }}>
            {/* Desktop */}
            <VStack align="stretch" spacing={'0'} display={{ base: 'none', md: 'flex' }}>
              <SidebarNav size="Large" logout={handleLogout} />
            </VStack>
            {/* Mobile */}
            <VStack align="stretch" spacing={'0'} display={{ base: 'flex', md: 'none' }}>
              <SidebarNav size="Small" logout={handleLogout} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      }
      signInContent={
        <DrawerContent maxW={'550px'} padding={0}>
          <DrawerHeader
            minH={'48px'}
            p={'8px 12px'}
            display={'flex'}
            borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
          >
            <DrawerCloseButton size={'md'} fontSize={'sm'} left={{ base: 'initial', md: '10px' }} />
            <Show below="md">
              <Center width={'full'} display={{ base: 'flex', md: 'none' }}>
                <Text textStyle={'heading-mobile-200'}>{title}</Text>
              </Center>
            </Show>
          </DrawerHeader>
          <DrawerBody p={{ base: '24px 16px', md: '24px 64px 60px 64px' }}>
            {accountFormToShow === 'login' && (
              <LoginForm type={AccountPageType.DRAWER} setAccountFormToShow={setAccountFormToShow} />
            )}
            {accountFormToShow === 'forgot_password' && (
              <ForgotPasswordForm
                type={AccountPageType.DRAWER}
                onCancel={() => {
                  setAccountFormToShow('login')
                }}
              />
            )}
            {accountFormToShow === 'create_account' && (
              <RegisterForm setAccountFormToShow={setAccountFormToShow} type={AccountPageType.DRAWER} />
            )}
          </DrawerBody>
        </DrawerContent>
      }
    />
  )
}
