import { Box, Image, Text, Flex, Stack, useToken, Alert, Icon, HStack } from '@chakra-ui/react'
import { core } from 'composable/chakra/figma-tokens'
import { NextLinkNoPrefetch } from 'composable/components/link'
import {
  getProductAttribute,
  getProductAttributes,
  getAttributeFromMasterVariant,
} from 'composable/helpers/utils/product-card-utils'
import { parseAttributeArrayIntoString } from 'composable/helpers/utils/string-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import { GoAlertFill } from 'react-icons/go'
import { IoImagesOutline } from 'react-icons/io5'
import { LegacyHorizontalProductCardCommon } from './types'
import { QuantityPicker, QuantityPickerProps } from '../../../composable/components/quantity-picker'

export interface LegacyHorizontalProductCardOutOfStockOrNotEnoughProps extends LegacyHorizontalProductCardCommon {
  totalPrice?: string
  totalDiscountedPrice?: string
  onChangeQuantity?: (val: number) => any
  quantityPickerProps?: QuantityPickerProps
  warranty?: {
    label: string
    value: string
  }
  isInStock?: boolean
}

export const LegacyHorizontalProductCardOutOfStockOrNotEnough = (
  props: LegacyHorizontalProductCardOutOfStockOrNotEnoughProps,
) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const [brandSecondary] = useToken('colors', ['brand.secondary'])

  const {
    columns = 3,
    size = 'lg',
    image,
    name,
    price,
    discountedPrice,
    totalPrice,
    totalDiscountedPrice,
    quantity,
    variant,
    onRemove,
    onChangeQuantity,
    isLoading,
    quantityPickerProps,
    url,
    masterProductData,
  } = props

  const isNotEnoughInStock =
    variant?.availableQuantity > 0 && variant?.availableQuantity < quantity ? variant?.availableQuantity : null

  const applyQuantity = (quantity: number) => async () => {
    onChangeQuantity(quantity)
  }

  const { src: imageSrc, alt: imageAlt = name } = image ?? {}
  const segment2Label = masterProductData?.find(({ name }) => name === 'segment2Label')?.value
  const segment3Label = masterProductData?.find(({ name }) => name === 'segment3Label')?.value
  const productAttributes = getProductAttributes(variant, segment2Label, segment3Label) ?? []
  const parsedProductAttributes = parseAttributeArrayIntoString(productAttributes) ?? ''
  const productItemNumber = getProductAttribute({ variant, attribute: 'itemNumber' })
  const brand = getAttributeFromMasterVariant({ masterProductData, attribute: 'brand' })

  const labels = {
    quantity: formatMessage({
      id: 'cart.item.quantity',
    }),
    itemPrice: formatMessage({
      id: 'cart.item.price',
    }),
    totalPrice: formatMessage({
      id: 'cart.item.totalPrice',
    }),
    apply: formatMessage({
      id: 'action.apply',
    }),
    remove: formatMessage({ id: 'action.remove' }),
    removeFromBag: formatMessage({ id: 'action.removeFromBag' }),
    addToWishlist: formatMessage({
      id: 'action.addToWishlist',
    }),
    outOfStock: formatMessage({
      id: 'cart.item.outOfStock',
    }),
    notEnoughStock: formatMessage({
      id: 'cart.item.notEnoughStock',
      values: { quantity: variant?.availableQuantity },
    }),
  }

  const quantityLabel = labels.quantity ?? ''
  const itemPriceLabel = labels.itemPrice ?? ''
  const removeFromBagLabel = labels.removeFromBag ?? ''
  const itemTotalPriceLabel = labels.totalPrice ?? ''
  const applyUpdatesLabel = labels.apply ?? ''
  const outOfStockMessage = labels.outOfStock ?? ''
  const notEnoughInStockMessage = labels.notEnoughStock ?? ''

  const cardOptions = {
    3: {
      sm: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: { props: { order: 3, marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 4, marginTop: 3 } },
        itemPrice: { type: 'text', label: true, props: { order: 2 } },
        contentColumn: {
          paddingTop: 4,
          paddingRight: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 3,
            marginTop: 3,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 4, marginTop: 3 } },
        itemPrice: { type: 'text', label: true, props: { order: 2 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
    4: {
      sm: {
        imageSize: {
          width: '80px',
          height: '106px',
        },
        details: { props: { order: 2, marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
        itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 2,
            marginTop: 1,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
        itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 4, gap: 3 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
    5: {
      sm: {
        imageSize: {
          width: '80px',
          height: '106px',
        },
        details: { props: { order: 2, marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
        itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 2,
            marginTop: 1,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
        itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 6, gap: 3 } },
        itemtotalPrice: { type: 'column', label: true, props: { order: 2, alignItems: 'end', marginRight: 4, gap: 3 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
  }

  const imageSize = cardOptions[columns][size].imageSize
  const quantityOptions = cardOptions[columns][size].quantity
  const itemPriceOptions = cardOptions[columns][size].itemPrice
  const itemtotalPriceOptions = cardOptions[columns][size].itemtotalPrice
  const detailsOptions = cardOptions[columns][size].details
  const contentColumn = cardOptions[columns][size]?.contentColumn

  const ConfiguredQuantityPicker = () => {
    return (
      <QuantityPicker
        isDisabled={!isNotEnoughInStock}
        hideLabel
        rootProps={{ maxW: 'fit-content', minHeight: 8 }}
        isLoading={isLoading}
        value={quantity}
        onChange={onChangeQuantity}
        quantityProps={{ minWidth: size === 'sm' ? 6 : 9 }}
        wrapperProps={{ py: 1, px: 0 }}
        {...quantityPickerProps}
      />
    )
  }

  return (
    <Box position={'relative'}>
      <Flex gap={4}>
        <Flex
          aspectRatio={3 / 4}
          width={imageSize.width}
          height={imageSize.height}
          onClick={image?.onClickImage}
          cursor={image?.onClickImage ? 'pointer' : undefined}
          opacity={!isNotEnoughInStock ? '0.6' : undefined}
        >
          {imageSrc ? (
            <Image src={imageSrc} alt={imageAlt} fit="contain" width={'full'} height={'full'} />
          ) : (
            <Flex justify={'center'} align={'center'} width={'full'} height={'full'} bgColor={'brand.muted'}>
              <IoImagesOutline size={'40px'} color={brandSecondary} />
            </Flex>
          )}
        </Flex>

        <Flex {...contentColumn} grow={1}>
          <Flex direction="column" flex={1}>
            <Text order={0} textStyle={'body-75'} color={'text-muted'}>
              {brand}
            </Text>
            <Text
              as={NextLinkNoPrefetch}
              href={url}
              order={1}
              textStyle={'body-100'}
              _hover={{ textDecoration: 'none', color: 'text-muted' }}
            >
              {name}
            </Text>

            {itemPriceOptions.type === 'text' && (
              <Flex position="absolute" bottom={10} right={0}>
                {discountedPrice && (
                  <Text mr={1} textStyle={'figma-strikethrough-75'}>
                    {discountedPrice}
                  </Text>
                )}
                <Text textStyle={'body-75'} color={discountedPrice ? 'danger.600' : 'text'}>
                  {price}
                </Text>
              </Flex>
            )}

            {quantityOptions.type === 'text' && (
              <Box {...quantityOptions?.props}>
                {quantityOptions.label && (
                  <Text mt={1} textStyle={'body-50'} color={'text-muted'}>
                    {quantityLabel}
                  </Text>
                )}
                <ConfiguredQuantityPicker />
              </Box>
            )}

            <Box
              {...detailsOptions?.props}
              textStyle={'body-50'}
              color={'text-muted'}
              style={{ wordWrap: 'break-word' }}
            >
              <Text>{productItemNumber ? `Item #: ${productItemNumber}` : ``}</Text>

              <Text>{parsedProductAttributes ?? ''}</Text>
            </Box>
          </Flex>

          {quantityOptions.type === 'column' && (
            <Stack {...quantityOptions?.props}>
              {quantityOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {quantityLabel}
                </Text>
              )}
              <ConfiguredQuantityPicker />
            </Stack>
          )}

          {itemPriceOptions.type === 'column' && (
            <Stack {...itemPriceOptions?.props}>
              {itemPriceOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {itemPriceLabel}
                </Text>
              )}
              <Box>
                <Text textStyle={'body-75'} color="danger.600">
                  {discountedPrice}
                </Text>
                <Text textStyle={discountedPrice ? 'figma-strikethrough-75' : 'body-75'}>{price}</Text>
              </Box>
            </Stack>
          )}
          {itemtotalPriceOptions?.type === 'column' && (
            <Stack {...itemtotalPriceOptions?.props}>
              {itemtotalPriceOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {itemTotalPriceLabel}
                </Text>
              )}
              <Box>
                <Text textStyle={'body-75'} color="danger.600">
                  {totalDiscountedPrice}
                </Text>
                <Text textStyle={totalDiscountedPrice ? 'figma-strikethrough-75' : 'body-75'}>{totalPrice}</Text>
              </Box>
            </Stack>
          )}
        </Flex>
      </Flex>

      <Alert
        status={isNotEnoughInStock ? 'warning' : 'error'}
        rounded="md"
        p={3}
        textStyle="body-75"
        borderLeft="4px solid"
        borderLeftColor={isNotEnoughInStock ? core.warning['warning-500'] : core.danger['danger-600']}
        bgColor={isNotEnoughInStock ? core.warning['warning-100'] : core.danger['danger-100']}
        mt={4}
      >
        {isNotEnoughInStock ? (
          <HStack gap={3}>
            <Icon as={GoAlertFill} boxSize={6} color={core.warning['warning-500']} />
            <Text>
              {notEnoughInStockMessage}
              {'  '}
              <Text
                as="button"
                textDecoration="underline"
                onClick={isLoading ? undefined : applyQuantity(isNotEnoughInStock)}
                cursor={isLoading ? 'initial' : 'pointer'}
                aria-label={removeFromBagLabel}
                color={isLoading ? 'text-muted' : undefined}
              >
                {applyUpdatesLabel}
              </Text>
            </Text>
          </HStack>
        ) : (
          <HStack gap={3}>
            <Icon as={GoAlertFill} boxSize={6} color={core.danger['danger-600']} />
            <Text>
              {outOfStockMessage}
              {'  '}
              <Text
                as="button"
                textDecoration="underline"
                onClick={isLoading ? undefined : onRemove}
                cursor={isLoading ? 'initial' : 'pointer'}
                aria-label={removeFromBagLabel}
                color={isLoading ? 'text-muted' : undefined}
              >
                {removeFromBagLabel}
              </Text>
            </Text>
          </HStack>
        )}
      </Alert>
    </Box>
  )
}
