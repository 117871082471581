import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { useAtgUser } from 'frontastic/contexts'
import { useFormat } from 'helpers/hooks/useFormat'
import { useMemo, useState } from 'react'
import { BRONZEIcon, GoldIcon, PLATINUMIcon, SILVERIcon } from './custom-icons'

interface LoyaltyPointsProps {
  price?: string
  pdpOrCartDrawer: 'PDP' | 'CART-DRAWER'
  marginTop?: string
}

export const LoyaltyPoints = ({ price, pdpOrCartDrawer, marginTop = '0px' }: LoyaltyPointsProps) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const intl = useFormat({ name: 'common' })
  const { userSessionData } = useAtgUser()

  const loyaltyPrice = useMemo(() => {
    const loyaltyPriceValue = parseFloat(price) * parseFloat(userSessionData?.currentTierPurchaseRatio)
    return Math.round(loyaltyPriceValue)
  }, [price, userSessionData?.currentTierPurchaseRatio])

  return (
    <Box className="loyaltyPointsPDPCont" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Box className="pointsRow">
        <Box className="points" aria-label={`loyalty ${loyaltyPrice} points`}>
          <Text className="point">
            <Popover trigger="hover">
              <PopoverTrigger>
                <Button
                  height={6}
                  className="num"
                  backgroundColor={'#D7E2EC'}
                  borderRadius="radii-base"
                  gap={1}
                  display={'flex'}
                  style={{
                    color: '#2F3F4F',
                    borderRadius: 'var(--radii-base,4px)',
                  }}
                  _hover={{ backgroundColor: '#D7E2EC' }}
                  alignItems="center"
                  justifyContent="center"
                  bgColor="#D7E2EC"
                  color="#2F3F4F"
                  padding="0 5px"
                  fontWeight={600}
                  fontSize="14px"
                  minWidth="0"
                  marginTop={marginTop}
                >
                  <Box textStyle="ui-button-75" display="inline" position="relative" top="1px">
                    +{loyaltyPrice} {pdpOrCartDrawer == 'PDP' ? 'Points' : ''}
                  </Box>
                  <InfoOutlineIcon display="inline" margin="2px 0" boxSize={4} marginLeft="3px" />
                </Button>
              </PopoverTrigger>
              <PopoverContent maxWidth={'160px'}>
                <PopoverArrow />
                <PopoverBody
                  p={3}
                  gap={2}
                  display={'flex'}
                  flexDirection={'column'}
                  backgroundColor="semantic-surface-primary"
                >
                  <Text minH={'auto'} textStyle="heading-desktop-50">
                    {intl.formatMessage({ id: 'rewards.heading' })}
                  </Text>
                  <Text textStyle="body-50">{intl.formatMessage({ id: 'rewards.subheading' })}</Text>
                  <UnorderedList ml={0} styleType="''">
                    <ListItem minH={8} display={'flex'} alignItems={'center'}>
                      <ListIcon width={'20px'} height={'20px'} as={BRONZEIcon} fontSize={'2rem'} />
                      <Text textStyle="body-50">{intl.formatMessage({ id: 'rewards.points1' })}</Text>
                    </ListItem>
                    <ListItem minH={8} display={'flex'} alignItems={'center'}>
                      <ListIcon width={'20px'} height={'20px'} as={SILVERIcon} fontSize={'2rem'} />
                      <Text textStyle="body-50">{intl.formatMessage({ id: 'rewards.points1' })}</Text>
                    </ListItem>
                    <ListItem minH={8} display={'flex'} alignItems={'center'}>
                      <ListIcon width={'20px'} height={'20px'} as={GoldIcon} fontSize={'2rem'} />
                      <Text textStyle="body-50">{intl.formatMessage({ id: 'rewards.points1.25' })}</Text>
                    </ListItem>
                    <ListItem minH={8} display={'flex'} alignItems={'center'}>
                      <ListIcon width={'20px'} height={'20px'} as={PLATINUMIcon} fontSize={'2rem'} />
                      <Text textStyle="body-50">{intl.formatMessage({ id: 'rewards.points1.5' })}</Text>
                    </ListItem>
                  </UnorderedList>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
